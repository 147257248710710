import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Conclusion`}</h5>
    <p>{`All in all I was pretty happy with the result, even when not everything worked as expected.
In the process of creating the website I learned a lot about marker tracking and got interested in doing
more `}<strong parentName="p">{`augmented reality`}</strong>{` projects.`}</p>
    <p>{`For the future I would like to understand the mechanisms behind the `}<strong parentName="p">{`AR.js`}</strong>{` library and maybe even
build my own library, to solve some of the problems I encountered.
Also it would be very interesting for me to find out what else is possible with `}<strong parentName="p">{`augmented reality`}</strong>{`,
how we can benefit from using it in our society, where it makes sense and where not.`}</p>
    <p><strong parentName="p">{`Ressources:`}</strong></p>
    <ul>
      <li parentName="ul">{`Link to the project code: `}<a parentName="li" {...{
          "href": "https://github.com/lItc0de/ar-slanted"
        }}>{`https://github.com/lItc0de/ar-slanted`}</a></li>
      <li parentName="ul">{`Link to the project website: `}<a parentName="li" {...{
          "href": "https://www.slanted.de/ai/"
        }}>{`https://www.slanted.de/ai`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      